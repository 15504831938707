'use client'

import Image from "@/core/components/Image";
import Link from "next/link";
import { memo } from "react";
import { Sms, Instagram, Location } from "iconsax-react";

import { logAction } from "@/core/helpers/analytics";

import idealLogo from "@/images/logo/IDEAL.svg";
import kominfoLogo from "@/images/logo-kominfo-color.png";
import fintechIndonesiaLogo from "@/images/logo-aftech-color.png";
import iso27001Logo from "@/images/logo-cbqa_iso_27001-color.png";
import ojkLogo from "@/images/logo-ojk.svg";
import iconTiktok from "@/images/icons/TikTokBlack.svg";
import appstoreLogo from "@/images/icons/IconAppStore.webp";
import googleplayLogo from "@/images/icons/IconGooglePlay.webp";

const Footer = ({ enableIdealPay = false }) => {

    return (
        <footer className="mt-auto relative">
            <div className="relative min-h-[665px] lg:min-h-[375px] bg-neutral-10 text-neutral-90">
                <div className={`relative z-10 w-full pt-8 md:pt-16 pb-20`}>
                    <div className="max-w-7xl mx-auto px-8">
                        <div className="w-[120px] mb-4 md:mb-8">
                            <Image src={idealLogo} alt="Ideal" title="ideal" objectFit="contain" height={56} width={128} loading="lazy" className="w-full" />
                        </div>

                        <div className="flex flex-col lg:flex-row gap-14">
                            <div className="flex-1">
                                <div className="relative">
                                    <Image src={ojkLogo} height={33} width={110} alt="OJK" objectFit="contain" />
                                </div>
                                <p className="text-xs sm:text-sm lg:text-base mt-8">
                                    IDEAL adalah perusahaan berbadan hukum di Indonesia yang terdaftar di Kementerian Hukum dan HAM. Layanan IDEAL sudah terdaftar di Kementerian Informasi, Komunikasi, dan Teknologi dengan nomor izin 001959.01/DJAI.PSE/01/2022.
                                </p>

                                <div className="flex items-center gap-3 mt-8">
                                    <div className="bg-white border border-neutral-30 rounded-[4px] h-[48px] min-w-[96px] flex items-center justify-center">
                                        <Image
                                            src={kominfoLogo}
                                            loading="lazy"
                                            objectFit="contain"
                                            alt="Kominfo"
                                            height={36}
                                            width={33}
                                            title="KOMINFO"
                                        />
                                    </div>
                                    <div className="bg-white border border-neutral-30 rounded-[4px] h-[48px] min-w-[96px] flex items-center justify-center">
                                        <Image
                                            src={fintechIndonesiaLogo}
                                            loading="lazy"
                                            objectFit="contain"
                                            alt="Fintech Indonesia"
                                            height={32}
                                            width={60}
                                            title="Fintech Indonesia"
                                        />
                                    </div>
                                    <div className="bg-white border border-neutral-30 rounded-[4px] h-[48px] min-w-[96px] flex items-center justify-center">
                                        <Image
                                            src={iso27001Logo}
                                            className="block"
                                            loading="lazy"
                                            objectFit="contain"
                                            alt="ISO 27001"
                                            height={34}
                                            width={30}
                                            title="CBQA ISO 27001"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1 relative">
                                <div className="flex flex-row md:gap-10">
                                    <div className="flex-1 relative">
                                        <div className="mb-4">
                                            <Link href="/partnership" className="lg:text-lg font-medium" onClick={() => logAction('HP_FOOT_Partnership')}>
                                                Partnership
                                            </Link>
                                        </div>
                                        <div className="mb-4">
                                            <Link href="/about" className="lg:text-lg font-medium" onClick={() => logAction('HP_FOOT_AboutUs')}>
                                                About Us
                                            </Link>
                                        </div>
                                        <div className="mb-4">
                                            <Link
                                                href="/referral"
                                                className="lg:text-lg font-medium"
                                                onClick={() => logAction('HP_FOOT_Referral')}>
                                                Referral
                                            </Link>
                                        </div>
                                        <div className="mb-4">
                                            <Link href="/faq" className="lg:text-lg font-medium" onClick={() => logAction('HP_FOOT_FAQ')}>
                                                FAQ
                                            </Link>
                                        </div>
                                        <div className="mb-4">
                                            <Link href="/privacy" className="lg:text-lg font-medium" onClick={() => logAction('HP_FOOT_PrivacyPolicy')}>
                                                Privacy & Policy
                                            </Link>
                                        </div>
                                        <div className="mb-4">
                                            <Link href="/term" className="lg:text-lg font-medium" onClick={() => logAction('HP_FOOT_TermsOfUse')}>
                                                Terms of Use
                                            </Link>
                                        </div>
                                        <div className="mb-4">
                                            <Link
                                                href={process.env.NEXT_PUBLIC_AP_URL}
                                                className="lg:text-lg font-medium"
                                                target="_blank"
                                                onClick={() => {
                                                    logAction('HP_FOOT_AgentPortal');
                                                }}>
                                                Agent Portal
                                            </Link>
                                        </div>
                                        <div className="mb-4">
                                            <Link href="/kpr-finder" className="lg:text-lg font-medium" onClick={() => logAction('HP_FOOT_KPRFinder')}>
                                                KPR Finder
                                            </Link>
                                        </div>
                                        <div className={+enableIdealPay === 1 ? "mb-4" : 'mb-4 md:mb-0'}>
                                            <Link
                                                href="/disclaimer"
                                                className="lg:text-lg font-medium"
                                                onClick={() => logAction('HP_FOOT_Disclaimer')}>
                                                Disclaimer
                                            </Link>
                                        </div>
                                        {+enableIdealPay === 1 ?
                                            <div className="mb-4 md:mb-0">
                                                <Link
                                                    href="/ideal-pay"
                                                    className="lg:text-lg font-medium"
                                                    onClick={() => logAction('HP_FOOT_IdealPay')}>
                                                    IDEAL Pay
                                                </Link>
                                            </div>
                                            : null
                                        }
                                    </div>

                                    <div className="flex-1 relative">
                                        <div className="mb-4">
                                            <Link href="/product/primary" className="lg:text-lg font-medium" onClick={() => logAction('HP_FOOT_PrimaryKPR')}>
                                                Primary Mortgage
                                            </Link>
                                        </div>
                                        <div className="mb-4">
                                            <Link href="/product/secondary" className="lg:text-lg font-medium" onClick={() => logAction('HP_FOOT_SecondaryKPR')}>
                                                Secondary Mortgage
                                            </Link>
                                        </div>
                                        <div className="mb-4">
                                            <Link href="/product/takeover" className="lg:text-lg font-medium" onClick={() => logAction('HP_FOOT_TakeoverKPR')}>
                                                Take Over Mortgage
                                            </Link>
                                        </div>
                                        <div className="mb-4">
                                            <Link href="/product/multiguna" className="lg:text-lg font-medium" onClick={() => logAction('HP_FOOT_Multiguna')}>
                                                Multiguna Loan
                                            </Link>
                                        </div>
                                        <div className="mb-4">
                                            <Link
                                                href="/product/ideal-pass"
                                                className="lg:text-lg font-medium"
                                                onClick={() => logAction('HP_FOOT_IdealScore')}>
                                                IDEAL Pass
                                            </Link>
                                        </div>
                                        {/* <div className="mb-4">
                                            <Link
                                                href={process.env.NEXT_PUBLIC_IDEAL_RUMAH123_URL}
                                                className="lg:text-lg font-medium"
                                                onClick={() => logAction('HP_FOOT_R123')}>
                                                {`IDEAL >< Rumah123`}
                                            </Link>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="relative">
                                <div className="mb-8 flex items-center gap-3.5">
                                    <a
                                        href={process.env.NEXT_PUBLIC_IOS_APP_URL}
                                        target="_blank"
                                        className="block"
                                        rel="noreferrer"
                                        onClick={() => {
                                            logAction('HP_LS_AppStore');
                                        }}
                                    >
                                        <Image
                                            src={appstoreLogo}
                                            alt="App Store Logo"
                                            loading="lazy"
                                            placeholder="blur"
                                            objectFit="contain"
                                            height={44}
                                            width={154}
                                        />
                                    </a>
                                    <a
                                        href={process.env.NEXT_PUBLIC_ANDROID_APP_URL}
                                        target="_blank"
                                        className="block"
                                        rel="noreferrer"
                                        onClick={() => {
                                            logAction('HP_LS_PlayStore');
                                        }}
                                    >
                                        <Image
                                            src={googleplayLogo}
                                            alt="Google Play Logo"
                                            loading="lazy"
                                            placeholder="blur"
                                            objectFit="contain"
                                            height={44}
                                            width={154}
                                        />
                                    </a>
                                </div>
                                <div className="grid gap-3 relative">
                                    <div className="flex items-start gap-3">
                                        <div className="h-6 relative w-6">
                                            <Location color="#25253F" size={24} variant="Bold" />
                                        </div>
                                        <div className="relative">
                                            <a href="https://goo.gl/maps/7Pac2Zz1fMm8pbBe9" target="_blank" rel="noreferrer">
                                                OCBC Tower, Jl. Prof. Dr. Satrio Kav.25, <br />21st floor. Jakarta 12940
                                            </a>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <div className="h-6 relative w-6">
                                            <Sms color="#25253F" size={24} variant="Bold" />
                                        </div>
                                        <div className="relative">
                                            <a href="mailto:hello@ideal.id" target="_blank" rel="noreferrer">
                                                hello@ideal.id
                                            </a>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <div className="h-6 relative w-6">
                                            <Instagram color="#25253F" size={24} variant="Bold" />
                                        </div>
                                        <div className="relative">
                                            <a href="https://www.instagram.com/ideal.idn/?hl=en" target="_blank" rel="noreferrer">
                                                @ideal.idn
                                            </a>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <div className="h-6 relative w-6">
                                            <Image src={iconTiktok} alt="Tiktok" height={24} width={24} />
                                        </div>
                                        <div className="relative">
                                            <a href="https://www.tiktok.com/@ideal.idn" target="_blank" rel="noreferrer">
                                                @ideal.idn
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default memo(Footer);
